<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <!-- Brand logo-->
    <b-link class="brand-logo">
      <b-img
        fluid
        :src="imgLogo2"
        alt="Logo New X Coins"
        style="max-height: 3rem"
      />
    </b-link>
    <!-- /Brand logo-->

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t("Página não encontrada") }} 🕵🏻‍♀️</h2>
        <p class="mb-2">
          {{ $t("Oops!") }} 😖
          {{ $t("A URL solicitada não foi encontrada neste servidor.") }}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/' }"
        >
          {{ $t("Voltar para a página inicial") }}
        </b-button>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";
import ProjectLogo from "@/layouts/logo/Logo.vue";

export default {
  components: {
    ProjectLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === "dark") {
        return require("@/assets/images/logo/newxcoins_logo_light.png");
      } else {
        return require("@/assets/images/logo/newxcoins_logo_dark.png");
      }
    },
    imgLogo2() {
      return require("@/assets/images/logo/newxcoins_logo_light.png");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
